<script lang="ts" setup>
import {computed, nextTick, toRefs, watch} from 'vue';
import {useSession} from '/src/core/session';
import {logEvent} from '/src/utils';
import router from '/src/router';

const session = useSession();
const {state} = session;
const {auth, subscription} = toRefs(state);

const isSubscriptionActive = computed(() => !!subscription.value && subscription.value?.status !== 'expired');

function logBuyBasic() {
    logEvent('button_buy', 'BASIC');
}

function logBuyPlus() {
    logEvent('button_buy', 'PLUS');
}

function buyPlus() {
    if (!auth.value) {
        return;
    }
    const {email, id} = auth.value.user;
    const plusURL = `${import.meta.env.VITE_CHECKOUT_URL}?checkout[email]=${email}&checkout[custom][user_id]=${id}`;
    window.location.href = plusURL;
}
</script>

<template>
    <h3 class="font-bold text-xl text-center">Upgrade your subscription to unlock all features</h3>
    <div class="flex flex-col mt-4 justify-around gap-4 sm:divide-secondary sm:divide-x sm:flex-row">
        <div class="flex flex-col justify-between mb-4 sm:mb-0">
            <div>
                <div class="text-xl text-center font-bold bg-secondary capitalize py-1 px-4 rounded-full w-min mx-auto">
                    Basic
                </div>
                <div class="my-4">
                    <div class="font-bold">Editor</div>
                    <ul class="list-disc list-inside">
                        <li>Code Generation</li>
                        <li>Image Processing</li>
                    </ul>
                    <div class="font-bold mt-2">Community Storage</div>
                    <ul class="list-disc list-inside">
                        <li>3 Public Projects</li>
                        <li>3 Public Screens (each Project)</li>
                    </ul>
                    <div class="font-bold mt-2">Assets</div>
                    <ul class="list-disc list-inside">
                        <li>Community Fonts</li>
                        <li>Community Icons</li>
                    </ul>
                </div>
            </div>
            <div>
                <div class="font-bold text-info text-center">FREE</div>
                <div
                    v-if="auth && !isSubscriptionActive"
                    class="btn btn-disabled btn-wide mt-4 uppercase"
                >
                    Current plan
                </div>
                <router-link
                    v-if="!auth"
                    class="btn btn-outline btn-primary btn-wide mt-4 uppercase"
                    @click="logBuyBasic"
                    to="/login"
                >
                    Sign Up
                </router-link>
            </div>
        </div>
        <div class="sm:pl-4 flex flex-col justify-between mb-4 sm:mb-0">
            <div>
                <div
                    class="text-xl text-center text-secondary font-bold bg-success capitalize py-1 px-4 rounded-full w-min mx-auto"
                >
                    Plus
                </div>
                <div class="my-4 sm:pl-4">
                    <div class="font-bold">Everything in Basic and...</div>
                    <div class="font-bold mt-2">Private Storage</div>
                    <ul class="list-disc list-inside">
                        <li>Unlimited Private Projects</li>
                        <li>Unlimited Private Screens</li>
                    </ul>
                    <!-- <div class="font-bold mt-2">Custom Fonts</div> -->
                    <div class="font-bold mt-2">Priority Support</div>
                    <div class="font-bold mt-2">No Ads</div>
                    <div class="font-bold mt-2 text-info">Coming soon</div>
                    <ul class="list-disc list-inside text-info">
                        <li>Custom Fonts Upload</li>
                    </ul>
                </div>
            </div>
            <div>
                <div class="font-bold text-success text-center">
                    $
                    <span class="text-xl">5.95</span>
                    / month
                </div>
                <div
                    v-if="auth && isSubscriptionActive"
                    class="btn btn-disabled btn-wide mt-4 uppercase"
                >
                    Current plan
                </div>
                <div
                    v-if="auth && !isSubscriptionActive"
                    class="btn btn-success btn-wide mt-4 uppercase"
                    @click="
                        logBuyPlus();
                        buyPlus();
                    "
                >
                    Buy Now
                </div>
                <router-link
                    to="/login#buy"
                    v-if="!auth && !isSubscriptionActive"
                    class="btn btn-success btn-wide mt-4 uppercase"
                    @click="logBuyPlus"
                >
                    Sign Up
                </router-link>
            </div>
        </div>
        <!-- <div class="pl-4 flex flex-col justify-between">
                    <div class="text-xl text-center text-primary">PRO</div>
                    <div class="my-4 pl-4">
                        <div class="font-bold">Everything in Plus and...</div>
                        <div class="font-bold mt-2">Team Workspace</div>
                        <ul class="list-disc list-inside">
                            <li>Collaboration</li>
                        </ul>
                        <div class="font-bold mt-2">Assets Manager</div>
                        <ul class="list-disc list-inside">
                            <li>HD Icon Packs</li>
                            <li>Unlimited Fonts</li>
                            <li>Components library</li>
                        </ul>
                        <div class="font-bold mt-2">Prototyping</div>
                        <ul class="list-disc list-inside">
                            <li>Interactive prototypes</li>
                            <li>User flow diagrams</li>
                        </ul>
                    </div>
                    <div class="font-bold text-success text-center">
                        $<span class="text-xl">{{ PRICE }}</span> / month
                    </div>
                    <div class="btn btn-primary btn-wide mt-4 uppercase" @click="buyPro">
                        JOIN WAITLIST
                    </div>
                </div> -->
    </div>
</template>
