<script lang="ts" setup>
import {useSession} from '../core/session';
import Navbar from './layout/Navbar.vue';

useSession();
// window.addEventListener('visibilitychange', () => {
//     if (document.visibilityState === 'visible') {
//         location.reload();
//     }
// });
</script>
<template>
    <Navbar></Navbar>
    <div class="justify-start px-3">
        <!-- <Sections /> -->
        <div class="flex flex-col w-full">
            <div class="flex flex-col gap-4 md:hidden text-center text-xl py-20 px-4">
                <p>Graphics editor for u8g2, Adafruit_GFX, TFT_eSPI, FlipperZero, InkPlate</p>
                <ul class="text-sm">
                    <li>Generates C source code</li>
                    <li>Converts images to bitmaps</li>
                    <li>Got hundreds of fonts</li>
                </ul>
                <p><b>This app works best on desktops</b></p>
                <a
                    href="https://www.youtube.com/embed/kTxVWBAW4ig?si=IJx4kYZQFi4Opm0W"
                    class="link link-primary"
                >
                    Watch the quick start guide
                </a>
                <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/kTxVWBAW4ig?si=IJx4kYZQFi4Opm0W"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                ></iframe>
            </div>
            <router-view></router-view>
        </div>
    </div>
</template>
<style lang="css">
.fui-editor {
    display: grid;
    margin: 0 auto;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.fui-editor__left {
    grid-area: 1 / 1 / 5 / 2;
}

.fui-editor__top {
    grid-area: 1 / 2 / 4;
}

.fui-editor__main {
    min-width: var(--main-width);
    grid-area: 2 / 2 / 3 / 3;
    min-height: 320px;
    padding-right: 8px;
}

.fui-editor__main-right {
    grid-area: 1 / 3 / 3 / 4;
}

.fui-editor__bottom {
    grid-area: 3 / 2 / 4 / 3;
    max-width: var(--main-width);
}

.fui-editor__bottom-right {
    grid-area: 3 / 3 / 4 / 4;
}

.fui-editor__canvas {
    max-height: 75vh;
    flex-shrink: 0;
    overflow: auto;
    display: flex;
    padding: 10px 20px 20px 20px;
    margin: 0 auto;
}

body {
    visibility: visible !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* -webkit-appearance: none; */
    /* margin: 0; */
}

/* Firefox */
input[type='number'] {
    /* -moz-appearance: textfield; */
}
</style>
